
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































































.hero__content {
  .news-single & {
    max-width: 100rem;
    margin: auto;
    text-align: center;
  }
}

.hero__content__title {
  @include fluid(
    font-size,
    (
      s: 42px,
      l: 64px,
      xl: 100px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 44px,
      l: 64px,
      xl: 100px,
    )
  );

  .news-single & {
    @include fluid(
      font-size,
      (
        s: 30px,
        l: 36px,
        xl: 60px,
      )
    );
    @include fluid(
      line-height,
      (
        s: 40px,
        l: 40px,
        xl: 70px,
      )
    );
  }
}

.hero__content__baseline {
  color: $c-blue;

  .news & {
    max-width: 76rem;
    color: $c-black;
  }

  @include mq(m) {
    width: col(15, 24);
  }
}

.hero__content__reading-time {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );

  color: $c-green;
  font-weight: 500;
  line-height: 1.3;
}

.hero__picture-outer {
  margin-inline: auto;

  .subpage &,
  .news-single & {
    @include aspect-ratio(21, 9);

    width: 100%;
    max-width: 140rem;

    img {
      @include image-fit;

      @include mq(m) {
        border-radius: 6rem;
      }
    }

    @include mq($until: m) {
      height: 32rem;
    }

    @include mq(m) {
      width: col(22, 24);
      margin-right: col(1, 24);
      margin-left: col(1, 24);
    }

    @include mq(xl) {
      width: col(20, 24);
      margin-right: col(2, 24);
      margin-left: col(2, 24);
    }

    @include mq(wrapper) {
      margin-inline: auto;
    }
  }
}

.hero__illustration-outer {
  max-width: 120rem;
  margin: auto;

  img {
    margin: auto;
  }
}
