
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.news-single__wrapper {
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
  }
}

.news-single__share {
  @include mq(m) {
    order: 0;
    width: col(3, 20);
  }
}

.news-single__content {
  @include mq(m) {
    order: 1;
    width: col(12, 20);
    margin-right: col(1, 20);
    margin-left: col(1, 20);
  }
}
